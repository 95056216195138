<script>
import { apiMixins } from "../../../mixins/api.js";
import { fileMixins } from "../../../mixins/file.js";
import { notificationMixins } from "../../../mixins/notification";
import { subjectMixins } from "../../../mixins/subject.js";
import Popup from "../../common/Popup.vue";
import Metadata from "../../common/Metadata.vue";
import RetentionSchedule from "../../common/RetentionSchedule.vue";

function performDownload(url, filename) {
  const a = document.createElement("a");
  a.download = filename;
  a.target = "_blank";
  a.href = url;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export default {
  mixins: [apiMixins, fileMixins, notificationMixins, subjectMixins],
  components: { Popup, Metadata, RetentionSchedule },
  props: {
    item: {
      type: Object,
      // default: null,
    },
  },
  data() {
    return {
      isSecret: false,
      isUpdating: false,
      suspensionOrders: "",
      apiItem: {},
      newSteward: null,
      isInDownloadLink: false,
      currentSubjects: [],
      allSubjects: [],
      isRetainHistoric: null,
      originalMetadata: [],
      currentMetadata: [],
      currentRetentionSchedule: null,
    };
  },
  computed: {
    downloadLink() {
      return window.location.href.replace("/properties/", "/download/");
    },
    availableSubjects() {
      return this.allSubjects.filter(
        (s) => !this.currentSubjects.includes(s.label)
      );
    },
    canUpdate() {
      if (this.apiItem.trashRepository) {
        return false;
      }
      if (this.currentRetentionSchedule && !this.currentRetentionSchedule.isActive) {
        return false;
      }
      return true;
    },
  },
  asyncComputed: {
    async getFile() {
      if (this.item) {
        this.isSecret = this.item.isSecret;
        this.apiItem = this.item;
      } else {
        let pathVariable = window.location.pathname.replace("/properties/", "");
        return await this.callAPI("item/file/" + pathVariable, {
          showLoader: false,
        })
          .then((response) => (this.apiItem = response))
          .then((apiItem) => (this.suspensionOrders = apiItem.suspensionOrders))
          .then(() => (this.isSecret = this.apiItem.isSecret))
          .then(() => (this.isRetainHistoric = this.apiItem.isRetainHistoric))
          .then(
            () =>
              (this.itemRetSchedule = this.apiItem.retSchedule.replace("#", ""))
          )
          .then(() => {
            this.currentSubjects = this.apiItem.tags.map((i) => i);
            this.loadSubjects();
          })
          .then(() => {
            this.currentMetadata = this.apiItem.metadata.map((i) => i);
            this.originalMetadata = this.apiItem.metadata.map((i) => i);
          });
      }
    },
  },
  methods: {
    downloadLinkHandler() {
      this.isInDownloadLink = true;
    },
    async downloadHandler() {
      const url = await this.getFileDownloadUrl(this.apiItem);
      performDownload(url, this.apiItem.name);
    },
    returnToBrowse() {
      this.$router.push({
        name: "browse",
        params: { id: this.apiItem.parentId },
      });
    },
    async submitPropertiesUpdate() {
      let update = {};

      if (
        this.currentRetentionSchedule &&
        this.currentRetentionSchedule.id !== this.itemRetSchedule.replace("#", "")
      ) {
        update.retentionSchedule = this.currentRetentionSchedule.id;
      }
      if (this.suspensionOrders !== this.apiItem.suspensionOrders) {
        update.suspensionOrders = this.suspensionOrders;
      }
      if (this.isSecret !== this.apiItem.isSecret) {
        update.isSecret = this.isSecret;
      }
      if (this.isRetainHistoric !== this.apiItem.isRetainHistoric) {
        update.retainHistoric = this.isRetainHistoric;
      }
      if (this.newSteward && this.newSteward !== this.apiItem.steward.name) {
        update.steward = this.newSteward;
      }
      if (!this.arraysEqual(this.currentSubjects, this.apiItem.tags)) {
        update.tags = this.currentSubjects;
      }
      if (
        !this.metadataArraysEqual(this.currentMetadata, this.originalMetadata)
      ) {
        update.metadata = this.currentMetadata;
      }
      await this.updateFile(this.apiItem, update);
      this.showSuccess(this.$t("file-updated"));
      this.returnToBrowse();
    },
    arraysEqual(a, b) {
      if (a === b) {
        return true;
      }
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false;
        }
      }
      return true;
    },
    metadataArraysEqual(a, b) {
      if (a === b) {
        return true;
      }
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; i++) {
        if (a[i].key !== b[i].key) {
          return false;
        }
        if (a[i].value !== b[i].value) {
          return false;
        }
      }
      return true;
    },
    async cancelPropertiesUpdate() {
      this.returnToBrowse();
    },
    copyToClipboard() {
      this.$refs.downloadLinkInput.focus();
      document.execCommand("copy");
      this.showSuccess(this.$t("link-copied"));
    },
    tagAdded(tag) {
      this.currentSubjects.push(tag.value);
    },
    tagRemoved(tag) {
      this.currentSubjects = this.currentSubjects.filter((s) => s !== tag);
    },
    metadataChanged(metadata) {
      this.currentMetadata = metadata;
    },
    onRetentionScheduleChangedHandler(selectedRetentionSchedule) {
      this.currentRetentionSchedule = selectedRetentionSchedule;
    },
    onRetainHistoricChangedHandler(isRetainHistoric) {
      this.isRetainHistoric = isRetainHistoric;
    },
    async loadSubjects() {
      var response = await this.getAvailableSubjects(this.folderId, {
        showLoader: true,
      });
      this.allSubjects = response.subjects;
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.page = 0;
        this.isSharing = false;
        this.isNewFolder = false;
        this.newFolderName = "";
      }
    },
  },
};
</script>

<template>
  <div v-if="this.apiItem && this.apiItem.permissions">
    <portal to="actions" v-if="this.apiItem.permissions.readAccess">
      <button @click.prevent="downloadLinkHandler">
        <m-icon icon="link" />
        {{ $t("copy-link") }}
      </button>
      <button @click.prevent="downloadHandler">
        {{ $t("download") }}
      </button>
    </portal>
    <section class="team">
      <h5>Steward - {{ this.apiItem.steward.name }}</h5>
      <section
        v-if="
          $store.state.auth.userData.isAdmin ||
            this.apiItem.steward.includesMe ||
            this.apiItem.owner.includesMe
        "
      >
        <div>
          <label>
            {{ $t("properties.new-steward") }}:
            <input type="text" v-model="newSteward" :disabled="this.apiItem.trashRepository"/>
          </label>
        </div>
      </section>
    </section>

    <RetentionSchedule
      id="retention-schedule"
      :item="this.apiItem"
      @retention-schedule-changed="onRetentionScheduleChangedHandler"
      @retain-historic-changed="onRetainHistoricChangedHandler"
    />

    <section class="suspension">
      <h5>{{ $t("suspension-orders") }}</h5>
      <section>
        <div
          v-if="
            this.apiItem.suspended &&
              (this.apiItem.suspensionOrders ||
                this.apiItem.suspensionOrders.length > 0)
          "
        >
          <p>{{ $t("suspension-order-parent") }}</p>
          <br />
        </div>
        <p>{{ $t("suspension-order-meaning") }}</p>
        <p>{{ $t("suspension-order-instruction") }}</p>
        <div style="margin-top: 1rem">
          <label>
            {{ $t("suspension-order-input-label") }}:
            <input
              type="text"
              v-model="suspensionOrders"
              :disabled="
                this.apiItem.trashRepository ||
                !(
                  this.apiItem.steward.includesMe ||
                  this.apiItem.owner.includesMe
                )
              "
            />
          </label>
        </div>
      </section>
    </section>
    <section class="confidentiality">
      <h5>{{ $t("confidentiality-heading") }}</h5>
      <section>
        <label>
          <input
            type="checkbox"
            v-model="isSecret"
            :disabled="
              this.apiItem.trashRepository ||
              !(
                this.apiItem.steward.includesMe || this.apiItem.owner.includesMe
              )
            "
          />
          {{ $t("confidentiality-label") }}
        </label>
      </section>
    </section>
    <section class="tags">
      <h5>{{ $t("keywords-heading") }}</h5>
      <section>
        <p>{{ $t("keywords-description") }}</p>
        <m-select
          id="add-tag"
          outlined
          @change="tagAdded"
          style="margin-top: 1rem"
          :disabled="this.apiItem.trashRepository"
        >
          <option selected value="">{{ $t("keywords-add") }}</option>
          <option
            v-for="subject in availableSubjects"
            :key="subject.id"
            :value="subject.label"
            >{{ subject.label }}</option
          >
        </m-select>
        <m-chip-set
          v-if="currentSubjects && currentSubjects.length"
          style="margin-top: 1rem"
        >
          <m-chip
            v-for="subject in currentSubjects"
            :key="subject"
            shouldRemoveOnTrailingIconClick="false"
            >{{ subject }}
            <m-icon
              icon="cancel"
              slot="trailingIcon"
              @click="tagRemoved(subject)"
              v-if="!apiItem.trashRepository"
            />
          </m-chip>
        </m-chip-set>
      </section>
    </section>

    <Metadata :item="apiItem" @metadata-changed="metadataChanged" />

      <section class="buttons">
        <button class="primary" @click="submitPropertiesUpdate" :disabled="!canUpdate">
          {{ $t("file-properties-confirm") }}
        </button>
        <button @click="cancelPropertiesUpdate">
          {{ $t("cancel") }}
        </button>
      </section>

    <Popup v-if="isInDownloadLink" @cancelled="isInDownloadLink = false">
      <template v-slot:header>{{ $t("download-link") }}</template>
      <template v-slot:body>
        <div>
          <label
            class="mdc-text-field mdc-text-field--outlined mdc-text-field--with-trailing-icon"
            style="width:100%"
          >
            <span class="mdc-notched-outline">
              <span class="mdc-notched-outline__leading"></span>
              <span class="mdc-notched-outline__notch">
                <span class="mdc-floating-label" id="my-label-id"></span>
              </span>
              <span class="mdc-notched-outline__trailing"></span>
            </span>
            <input
              class="mdc-text-field__input"
              type="text"
              aria-labelledby="my-label-id"
              :value="downloadLink"
              ref="downloadLinkInput"
            />
            <i
              class="material-icons mdc-text-field__icon mdc-text-field__icon--trailing"
              tabindex="0"
              role="button"
              @click="copyToClipboard"
              >content_copy</i
            >
          </label>
        </div>
      </template>
    </Popup>
  </div>
</template>

<style lang="scss" scoped>
.upload {
  padding-top: 1rem;
}

h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.options {
  display: flex;
  background: white;
  font-size: 0.875rem;

  > div {
    padding: 1.5rem;

    &:not(:last-child) {
      border-right: 1px solid #e0e0e0;
    }
  }
}

.required-field:after {
  content: " *";
  color: red;
}

.confidentiality,
.expiry,
.suspension,
.team,
.tags {
  > h5 {
    margin: 2rem 1rem 0;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    background: white;
    padding: 1.5rem;

    label {
      margin-left: 1rem;
      font-weight: bold;
    }

    input[type="text"] {
      background: #f9f9f9;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      color: black;
      padding: 0.5rem 1rem;
    }

    input[type="checkbox"] {
      margin-right: 0.5rem;
    }
  }

  select {
    margin: 1rem 0;
    width: 100%;
  }

  .warning {
    color: #ef0000;
    margin-top: 1rem;
  }
}

.progress {
  padding: 1.5rem 1.5rem 2rem;
  background: white;
  font-size: 0.875rem;
  border-top: 1px solid #e0e0e0;
}

.buttons {
  margin-top: 1rem;
}

.mdc-select,
.mdc-text-field {
  background-color: white;
  padding-left: 0;
  border-radius: 0;
}

.mdc-select .mdc-select__native-control,
.mdc-select .mdc-floating-label {
  padding-left: 0.5rem;
}

input[type="checkbox"] {
  background-color: #fff;
  padding-top: 0;
  padding-left: 0.1rem;
}

.relative-dates a.chip-trigger {
  display: block;
  padding: 0.5rem;
}

.relative-dates .mdc-chip {
  padding: 0;
}

.buttons button {
  margin-right: 1rem;
}

.buttons button {
  background: transparent;
  border: 1px solid #1275cc;
  color: #1275cc;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275cc;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}

.selected-retention-schedule {
  margin-top: 1rem;
  font-weight: bold;
}

button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}
</style>
