<script>
import { apiMixins } from "../../../mixins/api.js";
import Popup from "../../common/Popup.vue";
import RetentionSchedulePicker from "./RetentionSchedulePicker.vue";

export default {
  props: {
    item: {
      type: Object,
      required: false,
    },
  },
  components: { Popup, RetentionSchedulePicker },
  mixins: [apiMixins],
  emits: ["retention-schedule-changed"],
  data() {
    return {
      allRetentionSchedules: [],
      availableCategories: [],
      availableRetentionSchedules: [],
      currentRetentionSchedule: null,
      selectedRetentionSchedule: null,
      newRetentionScheduleVisible: false,
      parsedItemSchedule: this.item && this.item.defaultRetention && this.item.defaultRetention.replace("#", ""),
    };
  },
  asyncComputed: {},
  computed: {
    formattedRetentionSchedule() {
      if (this.currentRetentionSchedule && !this.currentRetentionSchedule.isActive) {
        return `${this.currentRetentionSchedule.id} ${this.currentRetentionSchedule.category} ${this.currentRetentionSchedule.title} ${this.currentRetentionSchedule.period} (INACTIVE)`;
      }
      if (this.currentRetentionSchedule) {
        return `${this.currentRetentionSchedule.id} ${this.currentRetentionSchedule.category} ${this.currentRetentionSchedule.title} ${this.currentRetentionSchedule.period}`;
      }
      return this.$t("retention-schedule.no-retention-schedule");
    },
  },
  methods: {
    async loadRetentionSchedules() {
      return this.callAPI("item/retention-schedules", { showLoader: false })
        .then((response) => {
          this.allRetentionSchedules = response.retentionSchedules;
          this.availableRetentionSchedules = this.allRetentionSchedules.filter((r) => r.isActive === true);
          const categories = this.availableRetentionSchedules
            .map((retentionSchedule) => retentionSchedule.category)
            .sort();
          const uniqueCategories = [...new Set(categories)];
          this.availableCategories = uniqueCategories;
        })
        .then(() => {
          if (this.isStoredItemRetentionScheduleEmpty()) {
            return;
          }
          this.currentRetentionSchedule = this.allRetentionSchedules.find(
            (r) => r.id === this.parsedItemSchedule
          );
          this.$emit("retention-schedule-changed", this.currentRetentionSchedule);
        });
    },
    isAdmin() {
      return this.$store.state.auth.userData.isAdmin;
    },
    isExpired() {
      return this.item && this.item.isExpired;
    },
    isOwner() {
      return this.item && this.item.owner && this.item.owner.includesMe;
    },
    isSteward() {
      return this.item && this.item.steward && this.item.steward.includesMe;
    },
    onNewRetentionScheduleHandler() {
      this.newRetentionScheduleVisible = true;
      this.selectedRetentionSchedule = null;
    },
    onNewRetentionScheduleCancelledHandler() {
      this.newRetentionScheduleVisible = false;
      this.selectedRetentionSchedule = null;
    },
    onClearRetentionScheduleConfirmedHandler() {
      this.newRetentionScheduleVisible = false;
      this.currentRetentionSchedule = { id: "", category: "", period: "", title: this.$t("retention-schedule.no-retention-schedule") }
      this.$emit("retention-schedule-changed", this.currentRetentionSchedule);
    },
    onNewRetentionScheduleConfirmedHandler() {
      this.newRetentionScheduleVisible = false;
      this.currentRetentionSchedule = this.selectedRetentionSchedule;
      this.$emit("retention-schedule-changed", this.currentRetentionSchedule);
    },
    onNewRetentionScheduleChangedHandler(selectedRetentionSchedule) {
      if (!selectedRetentionSchedule) {
        this.selectedRetentionSchedule = { id: "", category: "", period: "", title: this.$t("retention-schedule.no-retention-schedule") };
      } else {
        this.selectedRetentionSchedule = selectedRetentionSchedule;
      }
    },
    isStoredItemRetentionScheduleEmpty() {
      return (
        !this.item || !this.item.defaultRetention || this.item.defaultRetention === ""
      );
    },
    isNewRetentionScheduleAvailable() {
      return this.isSteward() || this.isOwner() || this.isAdmin();
    },
    hasRetentionScheduleChanged() {
      if (!this.item && this.selectedRetentionSchedule) {
        return true;
      }
      if (this.item && !this.currentRetentionSchedule && this.selectedRetentionSchedule) {
        return true;
      }
      if (this.item && this.currentRetentionSchedule && this.selectedRetentionSchedule) {
        return this.currentRetentionSchedule.id !== this.selectedRetentionSchedule.id;
      }
      
      return false;
    },
    shouldShowNewRetentionSchedule() {
      return (
        !this.item || this.isSteward() || this.isOwner() || this.isAdmin()
      );
    },
  },
  mounted() {
    this.loadRetentionSchedules();
  },
};
</script>

<template>
  <section class="expiry">
    <h5>
      {{ $t("default-retention-schedule.header") }} - {{ this.formattedRetentionSchedule }}
    </h5>
    <section>
      <div class="retention-block">
        {{ $t("default-retention-schedule.keep-until.file") }}
      </div>
      <div class="retention-block">
        {{ $t("retention-schedule.policies") }}
      </div>
      <div class="retention-block">
        <button
          class="primary"
          @click="onNewRetentionScheduleHandler"
          :disabled="!shouldShowNewRetentionSchedule() || (item && item.trashRepository)"
        >
          <m-icon icon="event" />
          {{ $t("default-retention-schedule.new") }}
        </button>
      </div>
    </section>

    <Popup
      v-if="newRetentionScheduleVisible"
      @cancelled="onNewRetentionScheduleCancelledHandler"
      @confirmed="onNewRetentionScheduleConfirmedHandler"
      :defaultButtons="false"
    >
      <template v-slot:header>{{ $t("retention-schedule-picker.header") }}</template>
      <template v-slot:body>
        <RetentionSchedulePicker
          id="retention-schedule-picker"
          :item="item"
          :availableRetentionSchedules="availableRetentionSchedules"
          :currentRetentionSchedule="currentRetentionSchedule"
          @retention-schedule-changed="onNewRetentionScheduleChangedHandler"
        />
      </template>
      <template v-slot:footer>
          <button type="button" 
            @click.stop="onNewRetentionScheduleCancelledHandler">
            <slot name="cancelLabel">{{ $t("cancel") }}</slot>
          </button>
          <button class="primary" type="submit"
          :disabled="!currentRetentionSchedule" 
            @click.prevent="onClearRetentionScheduleConfirmedHandler">
            <slot name="confirmLabel">No Default Schedule</slot>
          </button>
          <button class="primary" type="submit" 
          :disabled="!hasRetentionScheduleChanged()" 
            @click.prevent="onNewRetentionScheduleConfirmedHandler">
            <slot name="confirmLabel">{{ $t("ok") }}</slot>
          </button>
      </template>
    </Popup>
  </section>
</template>

<style lang="scss" scoped>
h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}

.expiry {
  > h5 {
    margin: 2rem 1rem 0;
  }

  > section {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    background: white;
    padding: 1.5rem;

    label {
      margin-left: 1rem;
      font-weight: bold;
    }

    input[type="checkbox"] {
      margin-right: 0.5rem;
    }

    button {
      margin-top: 1rem;
      //margin-left: 2rem;
      width: 300px;
      background: transparent;
      border: 1px solid #1275cc;
      color: #1275cc;
      border-radius: 4px;
      padding: 0.75rem 1rem;
      font-weight: bold;
      cursor: pointer;
      line-height: 1.6rem;

      &:disabled {
        cursor: default;
        opacity: 0.2;
      }

      &.primary {
        background: #1275cc;
        border-color: transparent;
        color: white;
      }

      > i {
        vertical-align: -0.35rem;
        padding-right: 0.25rem;
      }
    }

    .retention-block {
      margin-bottom: 1rem;
    }
  }

  .warning {
    color: #ef0000;
    margin-top: 1rem;
  }

  footer {
  padding: 0 1.5rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

footer > * {
  margin-left: 1.5rem;
}

  footer button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}
}
</style>
